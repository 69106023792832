import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'react-bootstrap';
import { enterprisePropType } from './enterprisePropTypes';
import { deleteSubsidiary, getSubsidiaries } from '../../services/enterprise.service';
import LabelValueLine from '../LabelValueLine/LabelValueLine';
import Tooltip from '../Tooltip/Tooltip';
import { AlertContext } from '../../contexts/alert.context';
import ActionChildModal from './ActionChildModal';

const SubsidiariesModal = ({ show, onClose, enterprise }) => {
  const { t } = useTranslation();
  const [subsidiaries, setSubsidiaries] = useState([]);
  const [showConfirmModal, setShowConfirmationModal] = useState(false);
  const [selected, setSelected] = useState();
  const { setNotif } = useContext(AlertContext);

  const fetchSubsidiaries = async () => {
    const res = await getSubsidiaries(enterprise.id);
    if (res.success) {
      setSubsidiaries(res.data);
    }
  };

  const removeSubsidiary = async (subsidiaryId) => {
    const res = await deleteSubsidiary(enterprise.id, subsidiaryId);
    if (res.success) {
      setNotif({
        message: t('Enterprise.Settings.Subsidiaries.removeSuccess'),
        variant: 'success',
        translated: true,
      });
      setSubsidiaries((prev) => prev.filter((subsidiary) => subsidiary.id !== subsidiaryId));
    } else {
      setNotif({
        message: t('Enterprise.Settings.Subsidiaries.removeFailed'),
        variant: 'danger',
        translated: true,
      });
    }
  };

  useEffect(() => {
    if (enterprise.is_customer && show) {
      fetchSubsidiaries();
    } else if (!show) {
      setSubsidiaries([]);
    }
  }, [enterprise, show]);

  const subsidiariesList = subsidiaries.filter((subsidiary) => subsidiary.id !== enterprise.id);

  return (
    <>
      <ActionChildModal
        show={showConfirmModal}
        onClose={() => setShowConfirmationModal(false)}
        onConfirm={() => {
          setShowConfirmationModal(false);
          removeSubsidiary(selected.id);
        }}
        childName={selected?.name}
        enterpriseName={enterprise?.name}
        remove
      />
      <Modal size="lg" centered show={show} onHide={onClose}>
        <Modal.Header closeButton>
          {t('Enterprise.Settings.Subsidiaries.title', { name: enterprise.name })}
        </Modal.Header>
        <Modal.Body className="overflow">
          <div className="mb-4">
            {t('Enterprise.Settings.Subsidiaries.description', { name: enterprise.name })}
          </div>
          {subsidiariesList?.length ? (
            <>
              {subsidiariesList
                .map((subsidiary) => (
                  <>
                    <LabelValueLine
                      className="mt-2 align-items-center"
                      key={subsidiary.id}
                      label={subsidiary.name}
                    >
                      <Button
                        variant="secondary"
                        size="sm"
                        data-tip
                        data-for={`remove-${subsidiary.id}`}
                        onClick={() => {
                          setSelected(subsidiary);
                          setShowConfirmationModal(true);
                        }}
                      >
                        {t('Enterprise.Settings.Subsidiaries.remove')}
                        <Tooltip id={`remove-${subsidiary.id}`}>
                          {t('Enterprise.Settings.Subsidiaries.removeTooltip')}
                        </Tooltip>
                      </Button>
                    </LabelValueLine>
                    <hr />
                  </>
                ))}
            </>
          ) : t('Enterprise.Settings.Subsidiaries.noSubsidiaries')}
        </Modal.Body>
      </Modal>
    </>
  );
};

SubsidiariesModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  enterprise: enterprisePropType.isRequired,
};

export default SubsidiariesModal;
