import { safeFetch } from '../helpers/response-handler';

const base = `${process.env.REACT_APP_URL_ENTERPRISE_API}/v1/enterprise`;
const baseAlt = `${process.env.REACT_APP_URL_ENTERPRISE_API_ALT}/v1/enterprise`;

export const getSubscription = (id) => (
  safeFetch(`${base}/stripe/subscriptions/${id}`)
);

export const getSubscriber = (id) => (
  safeFetch(`${base}/stripe/subscribers/${id}`)
);

export const upsertSubscription = (id, body) => (
  safeFetch(
    `${base}/stripe/subscribers/${id}?skipStripe=true`,
    { method: 'POST', body: JSON.stringify(body) },
  )
);

export const getVoucher = (id) => (
  safeFetch(`${base}/stripe/vouchers/${id}`)
);

export const getEnterpriseVoucher = (id) => (
  safeFetch(`${base}/stripe/enterprises/${id}/vouchers`)
);

export const getCustomerEvents = (id) => (
  safeFetch(`${baseAlt}/stripe/subscribers/${id}/events`)
);

export const setEnterpriseVoucher = (enterpriseId, voucherId) => (
  safeFetch(
    `${base}/stripe/vouchers/${voucherId}/enterprises/${enterpriseId}`,
    { method: 'POST' },
  )
);

export const deleteEnterpriseVoucher = (enterpriseId, voucherId) => (
  safeFetch(
    `${base}/stripe/enterprises/${enterpriseId}/vouchers/${voucherId}`,
    { method: 'DELETE' },
  )
);
