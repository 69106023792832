import {
  safeFetch,
} from '../helpers/response-handler';
import {
  queryToString,
  queryBoySearch,
} from '../helpers/utils';

const {
  REACT_APP_URL_COMPLIANCE_API,
  REACT_APP_URL_ENTERPRISE_API,
} = process.env;

const enterpriseBaseUrl = `${REACT_APP_URL_ENTERPRISE_API}/v1/enterprise/support`;

const newEnterpriseBaseUrl = `${REACT_APP_URL_ENTERPRISE_API}/v1/enterprise`;

export const getComplianceEnterprises = (query = {}) => safeFetch(
  `${REACT_APP_URL_COMPLIANCE_API}/v1/compliance/support/enterprises${queryToString(query)}`,
);

export const updateHasUser = async (idUser, enterpriseId, body) => (
  safeFetch(
    `${enterpriseBaseUrl}/enterprises/${enterpriseId}/users/${idUser}`,
    {
      method: 'PATCH',
      body: JSON.stringify(body),
    },
  )
);

export const search = (query) => safeFetch(
  `${REACT_APP_URL_COMPLIANCE_API}/v1/compliance/support/enterprises/search${queryToString(query)}`,
);

export const closeEnterprise = (enterpriseId) => safeFetch(
  `${enterpriseBaseUrl}/enterprises/${enterpriseId}`,
  {
    method: 'DELETE',
  },
);

export const getEnterprises = (options) => safeFetch(
  `${enterpriseBaseUrl}/enterprises${queryBoySearch(options)}`,
);

export const getEnterprise = (identifier, options) => safeFetch(
  `${enterpriseBaseUrl}/enterprises/${identifier}${queryBoySearch(options)}`,
);

export const getEnterpriseIbans = (identifier, options) => safeFetch(
  `${enterpriseBaseUrl}/enterprises/${identifier}/ibans${queryBoySearch(options)}`,
);
export const getEnterprisesWithDifferencesNotAccepted = (options) => safeFetch(
  `${REACT_APP_URL_ENTERPRISE_API}/v1/enterprise/support/enterprises/differences?${new URLSearchParams({
    limit: options?.limit || 10,
    q: options?.q || '',
  })}`,
);
export const getStatsEnterprisesWithDifferencesNotAccepted = () => safeFetch(
  `${REACT_APP_URL_ENTERPRISE_API}/v1/enterprise/support/enterprises/differences/stats`,
);
export const getEnterpriseWithDifferencesNotAccepted = (enterpriseId) => safeFetch(
  `${REACT_APP_URL_ENTERPRISE_API}/v1/enterprise/support/enterprises/differences/${enterpriseId}`,
);
export const getEnterpriseDifferences = (enterpriseId) => safeFetch(
  `${REACT_APP_URL_ENTERPRISE_API}/v1/enterprise/support/enterprises/${enterpriseId}/differences`,
);
export const acceptEnterpriseDifferences = (
  enterpriseId,
  differenceId,
  value,
  source,
  partials,
  additionalFlag,
) => safeFetch(
  `${REACT_APP_URL_ENTERPRISE_API}/v1/enterprise/support/enterprises/${enterpriseId}/differences/accept`,
  {
    method: 'POST',
    body: JSON.stringify({
      differenceId, value, source, partials, additionalFlag,
    }),
  },
);
export const refreshEnterprises = (enterprise_ids = []) => safeFetch(
  `${REACT_APP_URL_ENTERPRISE_API}/v1/enterprise/support/enterprises/refresh`,
  {
    method: 'PUT',
    body: JSON.stringify({
      enterprise_ids, sources: ['pappers'],
    }),
  },
);

export const getEnterpriseDifferencesAccept = (enterpriseId) => safeFetch(
  `${REACT_APP_URL_ENTERPRISE_API}/v1/enterprise/support/enterprises/${enterpriseId}/differences/accept`,
);

export const getCustomers = (identificationNumber, options) => safeFetch(
  `${enterpriseBaseUrl}/enterprises/${identificationNumber}/customers${queryBoySearch(options)}`,
);

export const getEnterpriseLegalForm = (id, options = {}) => safeFetch(
  `${enterpriseBaseUrl}/legal-forms/${id}${queryBoySearch(options)}`,
);

export const getEnterpriseLegalForms = (options = {}) => safeFetch(
  `${enterpriseBaseUrl}/legal-forms${queryBoySearch(options)}`,
);

export const getEnterprisesCount = (query = {}) => safeFetch(
  `${REACT_APP_URL_COMPLIANCE_API}/v1/compliance/support/enterprises/count${queryToString(query)}`,
);

export const updateEnterprise = (identificationNumber, body) => safeFetch(
  `${enterpriseBaseUrl}/enterprises/${identificationNumber}`,
  {
    method: 'PATCH',
    body: JSON.stringify(body),
  },
);

export const createEditingToken = async (identificationNumber, userEmail) => safeFetch(`${REACT_APP_URL_COMPLIANCE_API}/v1/compliance/support/enterprises/${identificationNumber}/token`, {
  method: 'POST',
  body: JSON.stringify({
    userEmail,
  }),
});

export const deleteEditingToken = async (
  userEmail,
) => {
  safeFetch(`${REACT_APP_URL_COMPLIANCE_API}/v1/compliance/support/token`, {
    method: 'DELETE',
    body: JSON.stringify({
      userEmail,
    }),
  });
};

export const getEditingTokens = async () => safeFetch(
  `${REACT_APP_URL_COMPLIANCE_API}/v1/compliance/support/tokens`,
);

export const getEnterpriseAddress = (identificationNumber, options = {}) => safeFetch(
  `${enterpriseBaseUrl}/enterprises/${identificationNumber}/addresses${queryBoySearch(options)}`,
);

export const getEnterpriseMembers = (
  identificationNumber,
  options = {},
) => safeFetch(
  `${enterpriseBaseUrl}/enterprises/${identificationNumber}/members${queryBoySearch(options)}`,
);

export const getEnterpriseRepresentatives = (
  enterpriseId,
) => safeFetch(
  `${enterpriseBaseUrl}/enterprises/${enterpriseId}/representatives`,
);

export const createConnectionConfig = (
  config,
) => safeFetch(`${enterpriseBaseUrl}/connections/config`, {
  method: 'POST',
  body: JSON.stringify(config),
});

export const updateConnectionConfig = (
  config,
) => safeFetch(`${enterpriseBaseUrl}/connections/configs/${config.id}`, {
  method: 'PATCH',
  body: JSON.stringify(config),
});

export const getConnectionConfig = (configId) => safeFetch(
  `${enterpriseBaseUrl}/connections/configs/${configId}`,
);

export const getConnectionConfigs = (options) => safeFetch(
  `${enterpriseBaseUrl}/connections/configs${queryBoySearch(options)}`,
);

export const modifyStatusConnectionConfig = (configId, status) => (
  safeFetch(
    `${enterpriseBaseUrl}/connections/configs/${configId}/${status}`,
    { method: 'PATCH' },
  )
);

export const getCustomFields = (customerId) => (
  safeFetch(`${enterpriseBaseUrl}/fields/customers/${customerId}`)
);

export const createSubsidiary = (enterpriseId, childId) => (
  safeFetch(
    `${newEnterpriseBaseUrl}/enterprises/${enterpriseId}/children/${childId}`,
    { method: 'POST' },
  )
);

export const deleteSubsidiary = (enterpriseId, childId) => (
  safeFetch(
    `${newEnterpriseBaseUrl}/enterprises/${enterpriseId}/children/${childId}`,
    { method: 'DELETE' },
  )
);

export const getSubsidiaries = (enterpriseId) => (
  safeFetch(
    `${newEnterpriseBaseUrl}/enterprises/${enterpriseId}/children`,
  )
);
