import PropTypes from 'prop-types';

export const enterprisePropType = PropTypes.shape({
  name: PropTypes.string,
  id: PropTypes.string,
  identification_number: PropTypes.string,
  created_at: PropTypes.string,
  no_payment_option: PropTypes.bool,
  is_vendor: PropTypes.bool,
  enterprise: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
    identification_number: PropTypes.string,
    created_at: PropTypes.string,
    no_payment_option: PropTypes.bool,
    is_vendor: PropTypes.bool,
    parent_id: PropTypes.bool,
    is_customer: PropTypes.bool,
    settings: PropTypes.shape(),
    deleted_at: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    ibans: PropTypes.array,
    seeDataUpgrades: PropTypes.bool,
    subscription: PropTypes.shape(),
    subscriber: PropTypes.shape(),
    country: PropTypes.string,
  }).isRequired,
  parent_id: PropTypes.bool,
  is_customer: PropTypes.bool,
  settings: PropTypes.shape(),
  deleted_at: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  ibans: PropTypes.array,
  seeDataUpgrades: PropTypes.bool,
  subscription: PropTypes.shape(),
  subscriber: PropTypes.shape(),
  country: PropTypes.string,
});
