import { getEnterprise, getEnterprises } from '../services/enterprise.service';
import { NotFound } from './catalog.errors';
import {
  BE_IDENTIFICATION_NUMBER_REGEX,
  DE_IDENTIFICATION_NUMBER_REGEX,
  FR_IDENTIFICATION_NUMBER_REGEX,
  UUID_REGEX,
} from './patterns';

export async function fetchEnterprises(
  search,
  queryboyOptions = {
    fields: [
      'id',
      'name',
      'identification_number',
      'is_vendor',
      'is_customer',
      'created_at',
      'parent_id',
    ],
    disableDefaultFilters: true,
  },
) {
  const matchings = [
    UUID_REGEX,
    FR_IDENTIFICATION_NUMBER_REGEX,
    DE_IDENTIFICATION_NUMBER_REGEX,
    BE_IDENTIFICATION_NUMBER_REGEX,
  ];
  let needSearch = false;
  if (matchings.some((m) => search.match(m))) {
    const res = await getEnterprise(search, queryboyOptions);
    if (res.hasError(NotFound)) needSearch = true;
    else return [res.data];
  } else {
    needSearch = true;
  }
  if (needSearch) {
    const res = await getEnterprises({
      ...queryboyOptions,
      search: { name: { $sw: search.toUpperCase().trim().replace(/\s/g, '_') } },
    });
    if (res.success) {
      return res.data;
    }
    if (res.hasError(NotFound)) {
      return null;
    }
  }
  return [];
}
