import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ActionChildModal = ({
  show,
  onClose,
  onConfirm,
  childName,
  enterpriseName,
  remove,
}) => {
  const { t } = useTranslation();

  const keyword = remove ? 'remove' : 'assign';

  return (
    <Modal size="lg" centered show={show} onHide={onClose}>
      <Modal.Header>
        <Modal.Title>{t(`Enterprise.Settings.${keyword}Child`)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {t(
            `Enterprise.Settings.${keyword}ChildDescription`,
            { child_name: childName, parent_name: enterpriseName },
          )}
        </p>
        <p className="text-danger font-size-20">
          <FontAwesomeIcon icon={faExclamationTriangle} />
          &nbsp;
          {t(`Enterprise.Settings.${keyword}ChildWarning`)}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={onClose}
        >
          {t('Common.cancel')}
        </Button>
        <Button
          variant="primary"
          onClick={onConfirm}
        >
          {t('Common.confirm')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ActionChildModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  childName: PropTypes.string,
  enterpriseName: PropTypes.string,
  remove: PropTypes.bool,
};

ActionChildModal.defaultProps = {
  childName: '',
  enterpriseName: '',
  remove: false,
};

export default ActionChildModal;
