import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { AWColors } from '../../helpers/utils';
import { fetchEnterprises as fetchEnterprisesService } from '../../helpers/search-enterprises';
import Tag from '../Tag/Tag';
import LabelValueLine from '../LabelValueLine/LabelValueLine';
import Tooltip from '../Tooltip/Tooltip';
import { AlertContext } from '../../contexts/alert.context';
import useDebounceSearch from '../../hooks/useDebounceSearch';
import SearchInput from '../SearchBox/SearchInput';

import { createSubsidiary, getEnterprise } from '../../services/enterprise.service';
import ActionChildModal from './ActionChildModal';
import SubsidiariesModal from './SubsidiariesModal';
import { enterprisePropType } from './enterprisePropTypes';

const CardGeneral = ({
  enterprise,
  setEnterprise,
  isHeadQuarter,
  onClickIbanLink,
  isHybrid,
  isProviderMode,
}) => {
  const { t } = useTranslation();
  const [headquarter, setHeadquarter] = useState();
  const [enterprises, setEnterprises] = useState([]);
  const { setNotif } = useContext(AlertContext);
  const [showModal, setShowModal] = useState(false);
  const [showSubsidiariesModal, setShowSubsidiariesModal] = useState(false);
  const [child, setChild] = useState();

  const fetchEnterprises = useDebounceSearch(async (search) => {
    const data = await fetchEnterprisesService(search);
    if (!data) setNotif({ message: t('ApiErrors.NotFound'), translated: true });
    else {
      setEnterprises(data);
    }
  }, undefined, [setNotif, setEnterprises]);

  const fetchHeadquarter = async () => {
    const res = await getEnterprise(enterprise?.settings?.headQuarter, {
      fields: [
        'id',
        'name',
        'identification_number',
        'is_vendor',
        'is_customer',
        'created_at',
        'parent_id',
        'deleted_at',
        'no_payment_option',
      ],
      disableDefaultFilters: true,
    });
    if (res.success) {
      setHeadquarter(res.data);
    }
  };

  const replaceByHeadquarter = () => {
    if (setEnterprise) setEnterprise(headquarter);
  };

  const handleHeaquarterClick = async (_enterprise, { confirmed } = { confirmed: false }) => {
    if (!confirmed) {
      setShowModal(true);
      setChild(_enterprise);
    } else if (confirmed && _enterprise) {
      if (_enterprise.parent_id) {
        setNotif({ message: t('Enterprise.Settings.alreadyChild'), translated: true });
      } else if (_enterprise.is_vendor && !_enterprise.is_customer) {
        setNotif({ message: t('Enterprise.Settings.vendorChild'), translated: true });
      } else {
        const res = await createSubsidiary(enterprise.id, _enterprise.id);
        if (res.success) {
          setNotif({
            message: t('Enterprise.Settings.childAssigned'),
            translated: true,
            variant: 'success',
          });
        }
      }
    }
  };

  useEffect(() => {
    if (enterprise && !isHeadQuarter) {
      fetchHeadquarter();
    } else {
      setHeadquarter(null);
    }
  }, [isHeadQuarter, enterprise]);

  const displayTag = () => {
    if (isHybrid) {
      return (
        <Tag
          size="tiny"
          color={AWColors.Blue}
          label={t('SurveyGeneral.Answerers.hybrid')}
        />
      );
    }
    if (enterprise.is_customer) {
      return (
        <Tag
          size="tiny"
          color={AWColors.Green}
          label={t('SurveyGeneral.Answerers.customer')}
        />
      );
    }
    return (
      <Tag
        size="tiny"
        color={AWColors.Red}
        label={t('SurveyGeneral.Answerers.provider')}
      />
    );
  };

  return (
    <>
      <ActionChildModal
        show={showModal}
        onClose={() => setShowModal(false)}
        onConfirm={() => {
          setShowModal(false);
          handleHeaquarterClick(child, { confirmed: true });
        }}
        childName={child?.name}
        enterpriseName={enterprise?.name}
      />
      <SubsidiariesModal
        show={showSubsidiariesModal}
        onClose={() => setShowSubsidiariesModal(false)}
        enterprise={enterprise}
      />
      <Card>
        <Card.Body>
          <div className="d-flex justify-content-between mb-3">
            <Card.Title>{enterprise.name}</Card.Title>
            <Card.Subtitle className="text-muted">{enterprise.identification_number}</Card.Subtitle>
          </div>
          <Card.Subtitle className="mb-3 font-12 label text-muted">{enterprise.id}</Card.Subtitle>
          <div className="mb-3 d-flex mr-2">
            {displayTag()}
          </div>
          <LabelValueLine className="mb-2" label={`${t('Enterprise.Settings.headQuarter')} ?`}>
            <span className="mr-10px">
              {isHeadQuarter ? t('Filter.yes') : t('Filter.no')}
            </span>
          </LabelValueLine>
          {headquarter ? (
            <LabelValueLine className="mb-2" label={t('Enterprise.Settings.headQuarter')}>
              <span className="mr-10px font-family-12">
                {headquarter.name}
                &nbsp;
                <span
                  className="link-style"
                  onClick={replaceByHeadquarter}
                  data-tip
                  data-for="headquarter-link"
                >
                  (
                  {headquarter.id}
                  )
                </span>
                <Tooltip place="top" id="headquarter-link">
                  {t('Enterprise.Settings.replaceByHeadquarter')}
                </Tooltip>
              </span>
            </LabelValueLine>
          ) : null}
          {!isProviderMode ? (
            <>
              <LabelValueLine
                label={t('Enterprise.Settings.assignChild')}
                className="align-items-center"
                boxClassName="flex-1 ms-3"
              >
                <SearchInput
                  className="mb-0"
                  placeholder={t('Enterprise.placeholderSearch')}
                  search={fetchEnterprises}
                  data={enterprises}
                  onReset={() => { setEnterprises([]); }}
                  onClick={handleHeaquarterClick}
                />
              </LabelValueLine>
              <div className="d-flex justify-content-end mt-3">
                <div
                  className="link-style"
                  onClick={() => setShowSubsidiariesModal(true)}
                >
                  {t('Enterprise.Settings.Subsidiaries.title', { name: enterprise.name })}
                </div>
              </div>
            </>
          ) : null}
          {isProviderMode ? (
            <div className="d-flex justify-content-end">
              <div
                className="link-style"
                onClick={onClickIbanLink}
              >
                {t('Enterprise.Iban.viewIbans')}
              </div>
            </div>
          ) : null}

        </Card.Body>
      </Card>
    </>
  );
};

CardGeneral.propTypes = {
  enterprise: enterprisePropType.isRequired,
  setEnterprise: PropTypes.func.isRequired,
  isHeadQuarter: PropTypes.bool.isRequired,
  isHybrid: PropTypes.bool,
  onClickIbanLink: PropTypes.func,
  isProviderMode: PropTypes.bool,
};

CardGeneral.defaultProps = {
  onClickIbanLink: () => {},
  isHybrid: false,
  isProviderMode: false,
};

export default CardGeneral;
