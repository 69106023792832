import React, {
  useContext,
  useEffect,
  useState,
  useMemo,
} from 'react';
import {
  Container,
  Row,
  Col,
  Button,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { EnterpriseViewMode } from '../../helpers/utils';
import Header from '../../components/Header/Header';
import {
  getEnterpriseIbans,
  getEnterpriseWithDifferencesNotAccepted,
} from '../../services/enterprise.service';
import { getEnterpriseSettings, getUserFromId } from '../../services/user.service';
import {
  getSubscriber,
  getSubscription,
  upsertSubscription,
} from '../../services/stripe.service';
import SearchInput from '../../components/SearchBox/SearchInput';
import EnterpriseSettings from '../../components/EnterpriseSettings/EnterpriseSettings';
import { AlertContext } from '../../contexts/alert.context';
import { fetchEnterprises as fetchEnterprisesService } from '../../helpers/search-enterprises';

const EnterpriseManagement = () => {
  const { t } = useTranslation();
  const [enterprises, setEnterprises] = useState([]);
  const [enterprise, setEnterprise] = useState();
  const [notFound, setNotFound] = useState(false);
  const [viewMode, setViewMode] = useState(EnterpriseViewMode.Customer);
  const { setNotif } = useContext(AlertContext);

  const queryboyOptions = {
    fields: [
      'id',
      'name',
      'identification_number',
      'is_vendor',
      'is_customer',
      'country',
      'created_at',
      'parent_id',
      'deleted_at',
      'no_payment_option',
    ],
    disableDefaultFilters: true,
    page_size: 100,
  };

  const queryboyOptionsIbans = {
    fields: [
      'id',
      'enterprise_id',
      'file_id',
      'iban',
      'created_at',
      'updated_at',
      'deleted_at',
      'bic',
      'status',
      'label',
    ],
    disableDefaultFilters: true,
    page_size: 100,
    sort: '-created_at',
  };

  const isHybrid = useMemo(() => (
    enterprise?.is_vendor && enterprise?.is_customer
  ), [enterprise]);

  const fetchCreatedByUser = async (createdBy) => {
    const userRes = await getUserFromId(
      createdBy,
      { fields: ['firstname', 'lastname', 'email'] },
    );
    if (userRes.success) {
      return userRes.data;
    }
    return undefined;
  };

  const fetchEnterpriseSubscription = async (_enterprise) => {
    const [subscriptionRes, subscriberRes] = await Promise.all([
      getSubscription(_enterprise.id),
      getSubscriber(_enterprise.id),
    ]);
    let user;
    if (subscriberRes.data?.created_by) {
      user = await fetchCreatedByUser(subscriberRes.data.created_by);
    }
    if (subscriberRes.success) {
      return {
        subscription: subscriptionRes.data,
        subscriber: {
          ...subscriberRes.data ?? {},
          created_by_user: user,
        },
      };
    }
    return {};
  };

  const updateSubscriber = (field) => async (value) => {
    if (enterprise?.id) {
      const res = await upsertSubscription(enterprise.id, { [field]: value });
      if (res?.success) {
        const data = await fetchEnterpriseSubscription(enterprise);
        setEnterprise((e) => ({
          ...e,
          ...data,
        }));
        setNotif({
          variant: 'success',
          message: field === 'code' ? t('Enterprise.Settings.subscriberUpdateCodeSuccess')
            : t('Enterprise.Settings.subscriberUpdateStripeIdSuccess'),
          translated: true,
        });
      } else if (res.hasError() && res.data.customCode === 'STRAPI015') {
        setNotif({
          variant: 'danger',
          message: t('Enterprise.Settings.subscriberUpdateFailedNotFound'),
          translated: true,
        });
      } else {
        setNotif({
          variant: 'danger',
          message: field === 'code' ? t('Enterprise.Settings.subscriberUpdateCodeFailed')
            : t('Enterprise.Settings.subscriberUpdateStripeIdFailed'),
          translated: true,
        });
      }
    }
  };

  const fetchEnterprises = async (search) => {
    const data = await fetchEnterprisesService(search, queryboyOptions);
    if (data === null) {
      setNotFound(true);
    } else {
      setNotFound(false);
      setEnterprises(data);
    }
  };

  const fetchSettings = async () => {
    const [res, resIbans, differences] = await Promise.all([
      getEnterpriseSettings(enterprise.id),
      getEnterpriseIbans(enterprise.id, queryboyOptionsIbans),
      getEnterpriseWithDifferencesNotAccepted(enterprise.id),
    ]);
    if (res.success) {
      setEnterprise((e) => ({
        ...e,
        settings: res.data.enterprise,
        ibans: resIbans.data || [],
        seeDataUpgrades: differences.success && !!differences.data?.fields?.length,
      }));
    }
  };

  const handleReset = () => {
    setEnterprises([]);
  };

  const handleClick = async (_enterprise) => {
    const data = await fetchEnterpriseSubscription(_enterprise);
    setEnterprise({
      ..._enterprise,
      ...data,
    });
  };

  useEffect(() => {
    if (enterprise?.id && !enterprise.settings) {
      fetchSettings();
    }
  }, [enterprise]);

  return (
    <div className="h-100 d-flex flex-column">
      <Header />
      <Container fluid className="pt-4 px-5">
        <Row>
          <Col xs={12} md={{ span: 4, offset: 4 }}>
            <SearchInput
              label={t('Enterprise.title')}
              placeholder={t('Enterprise.placeholderSearch')}
              search={fetchEnterprises}
              data={enterprises}
              onReset={handleReset}
              onClick={handleClick}
            />
          </Col>
          <Col xs={12}>
            {
              notFound ? (
                <span className="d-flex justify-content-center">
                  {t('ApiErrors.NotFound')}
                </span>
              ) : ''
            }
            {
              isHybrid ? (
                <div className="d-flex align-items-center">
                  <Button
                    className="me-3"
                    variant={viewMode === EnterpriseViewMode.Customer ? 'secondary' : 'outline'}
                    onClick={() => setViewMode(EnterpriseViewMode.Customer)}
                  >
                    {t('Enterprise.Settings.ViewMode.customer')}
                  </Button>
                  <Button
                    variant={viewMode === EnterpriseViewMode.Provider ? 'secondary' : 'outline'}
                    onClick={() => setViewMode(EnterpriseViewMode.Provider)}
                  >
                    {t('Enterprise.Settings.ViewMode.provider')}
                  </Button>
                </div>
              ) : null
            }
            {
              enterprise ? (
                <EnterpriseSettings
                  isHybrid={isHybrid}
                  enterprise={enterprise}
                  setEnterprise={setEnterprise}
                  onChangeCode={updateSubscriber('code')}
                  onChangeStripeId={updateSubscriber('stripe_id')}
                  viewMode={viewMode}
                />
              ) : ''
            }
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default EnterpriseManagement;
