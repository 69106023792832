import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const InputForm = ({ onSubmit, value: _value, disabled }) => {
  const [value, setValue] = useState(_value);

  useEffect(() => {
    setValue(_value);
  }, [_value]);

  return (
    <div className="d-flex">
      {value && value !== _value ? (
        <Button
          variant="success"
          onClick={() => { onSubmit(value); }}
          className="me-2"
        >
          <FontAwesomeIcon icon={faCheck} />
        </Button>
      ) : null}
      <Form.Control
        value={value}
        onChange={(e) => { setValue(e.target.value); }}
        disabled={disabled}
      />
    </div>
  );
};

InputForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.string,
  disabled: PropTypes.bool,
};

InputForm.defaultProps = {
  value: '',
  disabled: false,
};

export default InputForm;
